import React from "react"
import styled from "styled-components"

import DogParkListItem from "../components/DogParkListItem"
import dogParks from "../data/parks"

export default function DogParkList() {
  var filteredParks = dogParks["san_francisco"];
  var parkKeys = Object.keys(filteredParks);
  return (
    <div>
      {parkKeys.map((key, index) => {
        var filteredPark = filteredParks[key];
        return <DogParkListItem key={key} dogParkKey={key} dogPark={filteredPark} />
      })}
    </div>
  )
}
