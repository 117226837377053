import * as React from "react"
import styled from "styled-components"

import DogParkList from "../components/DogParkList"
import Header from "../components/Header"
import Footer from "../components/Footer"

import { Helmet } from 'react-helmet'

const Container = styled.div`
  font-family: monospace;
`

const Main = styled.div`
  margin: 3rem auto;
  max-width: 960px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

const IndexPage = () => {
  const mountainLakeImgSrc = "images/mountain_lake.jpg"
  return (
    <Container>
      <Helmet>
          <html lang='en' />
          <title>dogpark.fyi - san francisco's dog parks</title>
          <meta name='description' content="information about san francisco's dog parks" />
          <meta property='og:image' content={mountainLakeImgSrc} />
      </Helmet>
      <Main>
        <Header />
        <DogParkList />
      </Main>
      <Footer />
    </Container>
  )
}

export default IndexPage
