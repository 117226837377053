import React from "react"
import styled from "styled-components"

const Tag = styled.div`
  background: #ccc;
  padding: 0.4em 0.6em;
  display: inline-block;
  margin-right: 0.4em;
  margin-bottom: 0.4em;
  font-size: 0.8em;
`

const Title = styled.h4`
  margin: 0.4em 0;
`

export default function TagList(props) {
  var title = props.title;
  var tags = props.tags;
  return (
    <div class="tag-list">
      <Title>{title}</Title>
      {tags.map((tag, index) => {
        return <Tag key={index}>{tag}</Tag>
      })}
    </div>
  )
}
