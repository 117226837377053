import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import TagList from "../components/TagList"

const Container = styled.div`
  max-width: 280px;
  margin: 1em 1em 1em 0;
  display: inline-block;
  vertical-align: top;
  a {
    color: black;
  }
  a:hover {
    color: green;
  }
`

const Img = styled.img`
  width: 100%;
`

export default function DogParkListItem(props) {
  var dogPark = props.dogPark;
  var dogParkKey = props.dogParkKey;
  var linkPath = "/park/" + dogParkKey;
  return (
    <Container>
      <Link to={linkPath}>
        <h3>{dogPark.name}</h3>
        {dogPark.img &&
          <Img src={dogPark.img} />
        }
      </Link>
      <p>{dogPark.description}</p>
      {dogPark.seating && 
        <TagList title="Seating" tags={dogPark.seating}/>
      }
      {dogPark.enclosure && 
        <TagList title="Enclosure" tags={dogPark.enclosure}/>
      }
      {dogPark.water && 
        <TagList title="Water" tags={dogPark.water}/>
      }
      {dogPark.surfaces && 
        <TagList title="Surfaces" tags={dogPark.surfaces}/>
      }
    </Container>
  )
}
